export default {
    PersonaFisica: [
        {
          value: 1, 
          text: "Constancia de CUIL", 
          mandatory: true,
          endpoint_label: 'constancia-de-cuil'
        },
        {
          value: 2,
          text: "Documentación que respalde el origen de los fondos (últimos 3 recibos de sueldo, "+
            "contratos laboral vigente, últimos 3 pagos de monotributo)", 
          mandatory: true,
          endpoint_label: 'documentacion-que-re'
            
        },
        {
          value: 3,
          text:
            "Constancia de domicilio (factura de servicio/resumen de tarjeta/extracto bancario donde figure su dirección actual)", 
            mandatory: true,
            endpoint_label: 'constancia-de-domici'
        },
        {
          value: 4,
          text: "Últimas 2-3 declaraciones juradas de ingresos y/o patrimonio", 
          mandatory: false,
          endpoint_label: 'ultimas-2-3-declarac'
        }
      ],
    PersonaJuridica: [
        { 
          value: 1, 
          text: "Constancia de CUIT", 
          mandatory: true,
          endpoint_label: 'constancia-de-cuil'
        },
        {
          value: 2,
          text: "Últimas 2-3 declaraciones juradas de ingresos y/o patrimonio", 
            mandatory: true,
            endpoint_label: 'ultimas-2-3-declarac'
        },
        {
          value: 3,
          text: "Estatuto social constitutiva, con todas sus modificaciones y debidamente inscripto ante el Registro Público de Comercio aplicable", 
            mandatory: true,
            endpoint_label: 'estatuto-social-cons'
        },
        {
          value: 4,
          text: "Constancia de domicilio (cualquier factura que figure con su dirección)", 
          mandatory: true,
          endpoint_label: 'constancia-de-domici'
        },
        {
          value: 5,
          text: "Registro de accionistas debidamente inscripto en el Registro Pùblico de Comercio en el que se refleje la participación accionaria vigente", 
          mandatory: true,
          endpoint_label: 'registro-de-accionis'
        },
        {
          value: 6,
          text: "Datos identificatorios de los accionistas que posean más del 25% del capital accionario de la compañìa y de las afiliadas (incluyendo sin limitación, porcentaje de participación accionaria, nombre y apellido, documento de identidad vigente, número de clave fiscal, actividad principal)", 
          mandatory: true,
          endpoint_label: 'datos-identificatori'
        },
        {
          value: 7,
          text: "Acta de socios y administración que designa y acepta, respectivamente, los cargos de directores de la compañìa, debidamente inscriptas en el Registro Público de Comercio aplicable", 
          mandatory: true,
          endpoint_label: 'acta-de-socios-y-adm'
        },
        {
          value: 8,
          text: "Acta de registro de asistencia a asamblea en caso que el punto anterior corresponda a sociedades anónimas", 
          mandatory: true,
          endpoint_label: 'acta-de-registro-de'
        },
        {
          value: 9,
          text: "Estados contables anuales auditados y debidamente legalizado en el Consejo de Profesionales de Ciencias económicas aplicable correspondientes a los tres últimos años", 
          mandatory: true,
          endpoint_label: 'estados-contables-an'
        },
        {
          value: 10,
          text: "Poderes de administración vigentes", 
          mandatory: true,
          endpoint_label: 'poderes-de-administr'
        },
        {
          value: 11,
          text: "Documentos de identidad vigentes del representante legal y de los apoderados", 
          mandatory: true,
          endpoint_label: 'documentos-de-identi'
        }
      ]
}
<template>
  <b-container fluid class="py-5">
    <b-card>
      <b-row fluid class="pb-4">
        <b-col class="text-center">
            <strong> A continuación se le solicitan una serie de documentos que deberá proveer de manera digital </strong>
          </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-checkbox-group
            v-model="checkedFiles"
            name="files"
          >
            <b-form-checkbox
              v-for="checkbox in fileCheckboxes"
              :value="checkbox.value"
              :key="checkbox.value"
            >
              <span class="checkbox-file-label" :class="{ tachado: checkedFiles.includes(checkbox.value) }">
                {{ checkbox.text }} <span class="bithan-color" v-if="checkbox.mandatory">(*)</span>
              </span>
            </b-form-checkbox>
          </b-form-checkbox-group>

          <div class="documentacion-ob mt-4 mb-4">
            (*) Documentación obligatoria para el proceso. Si no cuenta con
            alguno de ellos, continue hasta el final del proceso y un ejecutivo
            de cuenta se comunicará con ustedes para asistirlo con la
            documentación pendiente.
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="dropzoneOptions"
          @vdropzone-file-added="fileUploaded"
          @vdropzone-removed-file="fileRemoved"
        ></vue-dropzone>
        </b-col>
      </b-row>

      <b-button
        block
        class="uppercase my-4"
        @click="confirmStep"
        :disabled="atLeastOneFileUploaded"
      >
        <span class="my-2 form-text">ENVIAR DOCUMENTACIÓN</span>
      </b-button>

      <b-row>
        <stepper class="stepper" :filled="currentStep" :size="maxSteps"/>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import ListadoDocumentos from '@/listado-documentos';
import stepsMixin from '@/mixins/stepsMixin'

const PersonaFisica = "F";

export default {
  name: "UploadDocuments",
  components: {
    vueDropzone: vue2Dropzone,
    Stepper: () => import("@/views/Components/Giftcards/Stepper")
  },
  mixins: [stepsMixin],
  mounted(){
    this.fileCheckboxes = this.personType === PersonaFisica ? 
      ListadoDocumentos.PersonaFisica :
      ListadoDocumentos.PersonaJuridica;
  },
  props: {
    personType: {
      default: PersonaFisica
    }
  },
  data: () => {
    return {
      imageData: "",
      form: {
        data: { dniFront: "", dniBack: "", dniAndSelfie: "" }
      },
      optionSelected: "",
      fileToUpload: "",
      dropzoneOptions: {
        autoProcessQueue: false,
        url: "http://localhost",
        addRemoveLinks: true,
        acceptedFiles: ".jpeg,.jpg,.png,.pdf",
        maxFilesize: 10,
        dictRemoveFile: "Quitar",
        dictDefaultMessage : "Arrastre aquí o Seleccione los archivos de su computadora (.pdf, .jpg, .png)"
      },
      fileCheckboxes: [],
      oneSelected: false,
      checkedFiles: [],
      uploadedFiles: []
    }
  },
  methods: {
    fileUploaded(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = event => {
        this.uploadedFiles.push({
          client_doc_name: file.name,
          client_doc_type: this.getExtention(file),
          client_doc: event.target.result.split(",").slice(-1)[0]
        });

       // this.$ref.vue2Dropzone.$emit('complete', file);
      }
    },
    getExtention(file){
      // divide el string por puntos '.' y devuelve el ultimo elemento
      return file.name.split(".").slice(-1)[0];
    },
    fileRemoved(file){
      const found = this.uploadedFiles
        .find(element => element.client_doc_name === file.client_doc_name);
      this.uploadedFiles.splice(found, 1);
    },
    confirmStep() {
      this.$emit("confirm-step", {
        data: {
          documents: this.uploadedFiles,
          client_doc_data: this.clientDocData()
        }
      })
    },
    clientDocData(){
      const array_doc_data = this.fileCheckboxes.map(file => {
        return [
          file.endpoint_label,
          this.checkedFiles.includes(file.value)
        ];
      }).sort((a, b) => {
        const isTrue = a[1] ? -1 : 1
        return (a[1] === b[1]) ? 0 : isTrue;
      });
      return Object.fromEntries(array_doc_data);
    }
  },
  computed: {
    atLeastOneFileUploaded() {
      return this.uploadedFiles.length < 1 || !this.oneSelected
    },    
  },
  watch: {
    checkedFiles(newValue, oldValue) {
      this.oneSelected = newValue.length > 0
    }
  }
}
</script>

<style lang="css" scoped>
.checkbox-file-label{
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.17px;
  line-height: 20px;
  font-size: 14px;
}

.tachado {
  text-decoration: line-through;
  font-style: italic;
}

.documentacion-ob {
  height: 42px;
  width: 450px;
  color: #005D7B;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.15px;
  line-height: 14px;
  text-align: center;
}
</style>
